//  Author: Matthew Ellison
//  You are free to use or modify this code if you reference the
//  author's email address matthew@ellisonconsulting.com
//  in a comment within your code

function classAttributeName()
// This function returns "className" for IE 5,6, 7 and "class" for all other browsers (including IE 8+)
{
	var classAttr
	classAttr="class"

	//if IE 5+ but not IE8
	if ((document.all)&&(document.getElementById)&&(!document.documentMode))
	{
		classAttr="className";
	}

return classAttr;
}